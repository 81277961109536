import { gsap } from "gsap";
const tl = gsap.timeline();
/**
 *
 * @param {gsap.core.Timeline} tl
 * @returns void
 */
export function animateHero() {
  const heroName = "#hero-name";
  const heroGreeting = "#hero-greeting";
  const heroDesc = "#hero-description";
  const heroAvatar = "#hero-avatar";
  tl.from(
    heroGreeting,
    {
      x: "-100%",
      delay: 0.8,
      opacity: 0,
      duration: 2,
      ease: "Power3.easeOut",
    },
    "<"
  )
    .from(
      heroName,
      {
        x: "-100%",
        delay: 0.5,
        opacity: 0,
        duration: 2,
        ease: "Power3.easeOut",
      },
      "<"
    )
    .from(
      heroDesc,
      {
        x: "-100%",
        delay: 0.1,
        opacity: 0,
        duration: 2,
        ease: "Power3.easeOut",
      },
      "<"
    )
    .from(
      heroAvatar,
      {
        x: "200%",
        delay: 0.5,
        opacity: 0,
        duration: 2,
        ease: "Power3.easeOut",
      },
      "<"
    );
}
