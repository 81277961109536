export function menuInteractions() {
  const button = document.querySelector("#menu-button");
  const menu = document.querySelector("#menu-list");
  const menuItem = document.querySelectorAll(".menu-item");

  menuItem.forEach((element, key, parent) => {
    element.addEventListener("click", () => toggleClasses(menu, ["hidden"]));
  });

  button.addEventListener("click", () => {
    toggleClasses(menu, ["hidden"]);
  });

  /**
   *
   * @param {Element} group
   * @param {Array<String>} classes
   */
  function toggleClasses(group, classes) {
    if (group && classes) {
      classes.forEach((_class) => group.classList.toggle(_class));
    }
  }
}
