"use strict";
// Page is loaded

import { animateHero } from "./animations";
import { mobile } from "./mobile";
import "./router";

animateHero();
if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  // true for mobile device
  console.log("mobile device");
  mobile();
} else {
  // false for not mobile device
  console.log("not mobile device");
}
