/**
 * typeof {Node}
 */
let heroContainer = "";
const root = window.document.querySelector("#root");
window.onload = () => {
  heroContainer = window.document.querySelector("#hero-container");
};

window.addEventListener("hashchange", function () {
  const paths = {
    "#about": About,
    "#techstack": TechStack,
    "#contact": Contact,
  };

  if (!Object.keys(paths).some((el) => el == this.location.hash)) {
    root.innerHTML = "";
    root.appendChild(heroContainer.cloneNode(true));
  } else {
    root.innerHTML = paths[this.location.hash]();
  }
});

function Home() {
  return originalRoot;
}

function About() {
  return `
    
  <section id="about" class="container pt-10 mx-auto max-width self-center">
  <article>
    <h1
      class="text-2xl font-bold text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight"
    >
      About Me
    </h1>
    <section class="mt-12">
      <p class="py-2 text-content lg:max-w-3xl">
        A seasoned software developer with more that 10 years of
        experience,He boasts a deep understanding of programming
        languages, agile methodologies, and the bedrock principles of
        computer science. He thrives in leadership roles, guiding teams of
        developers, mentoring junior engineers, and steering projects
        towards technical excellence.
      </p>
      <p class="py-2 text-content lg:max-w-3xl">
        Quality, maintainability, and scalability are his watchwords. He
        ensures software adheres to the highest standards, is built for
        easy future updates, and can seamlessly adapt to growth. Driven by
        a thirst for innovation, he stays abreast of the latest
        technologies and trends, leveraging them to optimize efficiency
        and deliver impactful solutions. He relishes the challenge of
        untangling complex problems, crafting high-performing software
        systems that delight end users.
      </p>
      <p class="py-2 text-content lg:max-w-3xl">
        Whether it's streamlining workflows, automating tasks, or building
        intuitive interfaces, he injects creativity and precision into
        every project he touches.
      </p>
    </section>
  </article>
</section>
    
    `;
}

function TechStack() {
  return `
  <section class="pt-10">
  <article>
    <h1
      class="text-2xl font-bold text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight"
    >
      Tech Stack
    </h1>
    <p class="py-2 text-content lg:max-w-3xl">
      Technologies I've been working with recently
    </p>
  </article>
  <article
    class="grid items-center grid-cols-4 gap-10 pt-6 md:grid-cols-5 lg:grid-cols-6 mt-12"
  >
    <img
      src="assets/techstack/html.png?width=400"
      alt="html stack"
      class="w-auto"
    />
    <img
      src="assets/techstack/css.png?width=400"
      title="CSS"
      alt=" css stack"
      class="w-auto"
    />
    <img
      src="assets/techstack/js.png?width=400"
      title="JavaScript"
      alt=" javascript stack"
      class="w-auto"
    />
    <img
      src="/assets/techstack/react.png?width=400"
      title="React"
      alt=" react js stack"
      class="w-auto"
    />
    <img
      src="assets/techstack/redux.png?width=400"
      title="Redux"
      alt=" redux state management stack"
      class="w-auto"
    />
    <img
      src="assets/techstack/tailwind.png?width=400"
      title="Tailwind CSS"
      alt="tailwind css stack"
      class="w-auto"
    />
    <img
      src="assets/techstack/bootstrap.png?width=400"
      title="Bootstrap"
      alt="bootstrap stack"
      class="w-auto"
    />
    <img
      src="assets/techstack/sass.png?width=400"
      title="SASS"
      alt="preprocessor sass to css stack"
      class="w-auto"
    />
  </article>
  <article>
    <h1
      class="pt-10 text-2xl font-bold text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight"
    >
      Tools
    </h1>
  </article>
  <article
    class="grid items-center grid-cols-4 gap-10 pt-6 md:grid-cols-5 lg:grid-cols-6"
  >
    <img
      src="assets/techstack/vscode.png?width=400"
      title="Visual Studio Code"
      alt="Visual Studio code"
    />
    <img
      src="assets/techstack/git.png?width=400"
      title="Git"
      alt="Git"
      class="w-auto"
    />
    <img
      src="assets/techstack/github.png?width=400"
      title="Github"
      alt="Github"
      class="w-auto"
    />
    <img
      src="assets/techstack/figma.png?width=400"
      title="Figma"
      alt="Figma"
      class="w-auto"
    />
    <img
      src="assets/techstack/npm.png?width=400"
      title="NPM"
      alt="NPM"
      class="w-auto"
    />
    <img
      src="assets/techstack/postman.png?width=400"
      title="Postman"
      alt="Postman"
      class="w-auto"
    />
  </article>
</section>
    `;
}

function Contact() {
  return `
  <section
  id="contact"
  class="container pt-10 pb-20 mx-auto max-width vw-height"
>
  <h6
    class="mt-24 text-2xl font-semibold text-center md:text-3xl lg:text-6xl text-dark-heading dark:text-light-heading md:font-bold"
  >
    For any questions please drop a mail
  </h6>
  <h3
    class="pt-5 text-2xl  font-semibold text-center md:text-4xl lg:text-6xl text-gradient md:font-bold md:pt-10 md:pb-6"
  >
    <a href="mailto:kelmants@gmail.com">kelmants@gmail.com</a>
  </h3>
  <span class="block text-xl font-light text-center text-content">or</span>
  <h3
    class="pt-2 text-2xl font-semibold text-center md:text-4xl lg:text-6xl text-gradient md:font-bold md:py-6"
  >
    <a href="tel:+5491126493011">+5491126493011</a>
  </h3>
</section>
    `;
}

function ErrorContent() {
  return `
        <span>Error content</span>
    `;
}
